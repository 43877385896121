<template>
  <div>
    <el-button
      style="margin-bottom: 10px"
      v-if="showLevelTable"
      @click="showLevelTable = false"
      icon="el-icon-back"
    >返回</el-button>
    <el-form :inline="true">
      <el-form-item label="审核员">
        <el-select
          v-model="UserNo"
          filterable
          remote
          reserve-keyword
          placeholder="请输入姓名"
          :remote-method="remoteMethod"
          :loading="userLoading"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.userName"
            :value="item.userNo"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" icon="el-icon-search" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-if="!showLevelTable"
      :data="tableData.slice(start1, end1)"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      style="width: 98%"
      id="table"
      :height="elementHeight"
      border
    >
      <el-table-column type="index" width="100" label="序号"></el-table-column>
      <el-table-column prop="userName" label="姓名"></el-table-column>
      <el-table-column prop="userNo" label="编号"></el-table-column>
      <el-table-column prop="auditCount" label="审核次数">
        <template slot-scope="scope">
          <span style="color:blue;border-bottom:1px solid" @click="showLevelData(scope.row)">
            {{
            scope.row.auditCount
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="questionCount" label="问题点数"></el-table-column>
    </el-table>
    <el-table
      v-else
      :data="levelData.slice(start2, end2)"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      style="width: 98%"
      id="table"
      :height="elementHeight-40"
      border
    >
      <el-table-column type="index" width="100" label="序号"></el-table-column>
      <el-table-column prop="userName" label="姓名">
        <template>
          <span>{{ userInfo.userName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userNo" label="编号">
        <template>
          <span>{{ userInfo.userNo }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="auditType" label="审核次数">
        <template slot-scope="scope">
          <span v-if="scope.row.auditType == 0">股份公司年度审核</span>
          <span v-if="scope.row.auditType == 1">股份子公司年度审核</span>
          <span v-if="scope.row.auditType == 2">股份公司季度审核</span>
          <span v-if="scope.row.auditType == 3">股份子公司季度审核</span>
          <span v-if="scope.row.auditType == 5">自查自纠</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="审核类型"></el-table-column>
      <el-table-column prop="saveTime" label="保存时间">
        <template slot-scope="scope">
          {{
          scope.row.saveTime ? scope.row.saveTime.split('T')[0]: ''
          }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="!showLevelTable"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage1"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      class="_Pagination"
    ></el-pagination>
    <el-pagination
      v-else
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage2"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize2"
      layout="total, sizes, prev, pager, next, jumper"
      :total="levelData.length"
      class="_Pagination"
    ></el-pagination>
  </div>
</template>

<script>
import { get } from '../../api/http'
export default {
  data() {
    return {
      UserNo: '',
      elementHeight: 0,
      tableFourObj: {},
      tableData: [],
      tableRowIndex: null,
      typeBtnIndex: 0,
      userInfo: {},
      levelData: [],
      showLevelTable: false,
      // 分页
      start1: 0,
      end1: 10,
      curSize1: 10,
      currentPage1: 1,
      // 等级分页
      start2: 0,
      end2: 10,
      curSize2: 10,
      currentPage2: 1,
      userList: [],
      userLoading: false,
      allUserList: [],
    }
  },
  mounted() {
    this.getElementHeight()
    this.getDataList()
    this.getUser()
  },
  methods: {
    remoteMethod(query) {
      if (query !== '') {
        if (this.allUserList.length > 0) {
          this.userList = this.allUserList.filter((item) => {
            return item.userName.toLowerCase().indexOf(query.toLowerCase()) > -1
          })
        } else {
          this.userLoading = true
          get('/api/User').then((res) => {
            if (res.code == 200) {
              this.userLoading = false;
              this.userList = res.data.filter((item) => {
                return (
                  item.userName.toLowerCase().indexOf(query.toLowerCase()) > -1
                )
              })
            }
          })
        }
      } else {
        this.userList = []
      }
    },
    getUser() {
      get('/api/User').then((res) => {
        if (res.code == 200) {
          this.allUserList = res.data
        }
      })
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 52 + 20 + 45) //分页加底部距离
        console.log(document.getElementById('table').offsetTop)
      })
    },
    getDataList() {
      get('/api/PeopleAuditCensus').then((res) => {
        if (res.code == 200) {
          this.tableData = res.data
        }
      })
    },
    handleQuery() {
      if (this.UserNo) {
        get('/api/PeopleAuditCensus?UserNo=' + this.UserNo).then((res) => {
          if (res.code == 200) {
            this.tableData = res.data
          }
        })
      } else {
        this.getDataList()
      }
    },
    showLevelData(row) {
      this.curSize2 = 10
      this.currentPage2 = 1
      this.end2 = 10
      this.start2 = 0
      this.userInfo = row
      this.showLevelTable = true
      get(
        '/api/PeopleAuditCensus/GetAuditLevel?UserNo=' +
          row.userNo +
          '&UserName=' +
          row.userName
      ).then((res) => {
        if (res.code == 200) {
          this.levelData = res.data
        }
      })
    },
    // 分页
    handleSizeChange(e) {
      var curSize = !this.showLevelTable ? 'curSize1' : 'curSize2'
      var start = !this.showLevelTable ? 'start1' : 'start2'
      var end = !this.showLevelTable ? 'end1' : 'end2'
      var currentPage = !this.showLevelTable ? 'currentPage1' : 'currentPage2'
      this[curSize] = e
      this[start] = (this[currentPage] - 1) * this[curSize]
      this[end] = this[start] + this[curSize]
    },
    handleCurrentChange(e) {
      var curSize = !this.showLevelTable ? 'curSize1' : 'curSize2'
      var start = !this.showLevelTable ? 'start1' : 'start2'
      var end = !this.showLevelTable ? 'end1' : 'end2'

      this[start] = (e - 1) * this[curSize]
      this[end] = this[start] + this[curSize]
    },
  },
}
</script>

<style lang="less" scoped>
.selec-inp {
  display: flex;
  align-items: center;
  padding: 20px 0;
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 180px !important;
  }
}
.select-name {
  width: 400px !important;
  margin-right: 10px;
  .el-select {
    width: 280px !important;
    /deep/.el-select__tags {
      overflow: hidden !important;
      flex-wrap: nowrap !important;
    }
    input {
      border: none;
      outline: none !important;
    }
  }
  .input-name {
    width: 180px !important;
    border: 1px solid #c0c0c0d5;
    height: 28px !important;
    line-height: 28px !important;
    font-size: 13px !important;
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0fc;
  }
  :-moz-placeholder {
    color: #c0c0c0fc;
  }
  ::moz-placeholder {
    color: #c0c0c0fc;
  }
  :-ms-input-placeholder {
    color: #c0c0c0fc;
  }
}

.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}
.select-btn {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>